export default class {
  static spec(card, cardType) {
    if ($tpu.globals.isStandaloneView) {
      return this._editDialogAction(card, cardType)
    }

    return {
      "action": "commands/custom",
      "name": "cards/open",
      "properties": {
        "cardId": card.id
      }
    }
  }

  static _editDialogAction(card, cardType) {
    return {
      "action": "dialogs/show",
      "showClose": true,
      "closeOnBlur": true,
      "body": {
        "padding": {
          "top": 18,
          "left": 20,
          "right": 20,
          "bottom": 22
        },
        "childViews": [
          {
            "view": "panels/custom",
            "width": "matchParent",
            "template": "cards/show",
            "data": {
              "id": 'card_show',
              "cardId": card.id,
              "cardType": cardType
            }
          }
        ]
      }
    }
  }

  static actionIconSpec(card, cardType) {
    let openIcon, openOnClick

    // const icon = $tpu.keys.icnEdit()
    // const onClick = this._editDialogAction(card, cardType)

    if ($tpu.globals.isStandaloneView) {
      openIcon = $tpu.keys.icnOpenInNew()
      openOnClick = {
        "action": "windows/openWeb",
        "url": card.url
      }
    } else {
      openIcon = $tpu.keys.icnEdit()
      openOnClick = this._editDialogAction(card, cardType)
      // {
      //   "action": "commands/custom",
      //   "name": "cards/open",
      //   "properties": {
      //     "cardId": card.id
      //   }
      // }
    }

    return {
      "view": "button",
      "styleClasses": $tpu.styles.iconButtonClasses().concat(["show-on-hover"]),
      "icon": { "material": { name: openIcon } },
      "onClick": openOnClick
    }

    // let icon, onClick
    // if ($tpu.globals.isStandaloneView) {
    //   icon = $tpu.keys.icnOpenInNew()
    //   onClick = {
    //     "action": "windows/openWeb",
    //     "url": card.url
    //   }
    // } else {
    //   icon = $tpu.keys.icnOpenInCurrent()
    //   onClick = {
    //     "action": "commands/custom",
    //     "name": "cards/open",
    //     "properties": {
    //       "cardId": card.id
    //     }
    //   }
    // }

    // return {
    //   "view": "button",
    //   "styleClasses": ["icon", "show-on-hover"],
    //   "icon": { "material": { name: icon } },
    //   "onClick": onClick
    // }
  }
}
